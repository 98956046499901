<template>
  <div>
    <b-card-actions action-collapse :title="$t('Chat')">
      <chat-window
        :theme="styleChat"
        v-if="user"
        :current-user-id="user"
        :rooms="rooms"
        :messages="messages"
        @send-message="sendMessage"
        @fetch-messages="getRoomInfo"
        :loading-rooms="loadingRooms"
        :rooms-loaded="true"
        :messages-loaded="messagesloaded"
        @send-message-reaction="sendReaction"
        @edit-message="editMessage"
        @delete-message="deleteMessage"
        :styles="styles"
        @open-file="openFile"
      />
    </b-card-actions>

    <b-modal ref="my-modal" hide-footer title="Conversation image">
      <img :src="activeImage" style="width: 100%" />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-3"
        variant="outline-secondary"
        block
        @click="hideModal"
      >
        Close Me
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BModal,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  data() {
    return {
      // styleChat:this.$store.state.appConfig.layout.skin || "light",
      rooms: [],
      messages: [],
      messagesloaded: false,
      roomId: null,
      otherUser: null,
      loadingRooms: true,
      showModal: false,
      activeImage: "",
      interval: null,
      order: {},
      styles: {
        general: {
          color: "#9600ff",
          colorSpinner: "#9600ff",
        },
        message: {
          colorUsername: "#9600ff",
        },
        header: {
          colorRoomName: "#9600ff",
          colorRoomInfo: "#9600ff",
        },
        message: {
          colorNewMessages: "#9600ff",
          borderStyleReactionMe: "1px solid #9600ff",
          backgroundReactionMe: "rgba(115, 103, 240, 0.2)",
          backgroundReactionHoverMe: "rgba(115, 103, 240, 0.7)",
          borderStyleReactionHoverMe: "1px solid #9600ff",
          colorReactionCounterMe: "#9600ff",
        },
        icons: {
          search: "#9ca6af",
          add: "#9600ff",
          toggle: "#9600ff",
          menu: "#0a0a0a",
          close: "#9ca6af",
          closeImage: "#fff",
          file: "#9600ff",
          paperclip: "#9600ff",
          closeOutline: "#000",
          send: "#9600ff",
          sendDisabled: "#9ca6af",
          emoji: "#9600ff",
          emojiReaction: "rgba(0, 0, 0, 0.3)",
          document: "#9600ff",
          pencil: "#9e9e9e",
          checkmark: "#9e9e9e",
          checkmarkSeen: "#0696c7",
          eye: "#fff",
          dropdownMessage: "#fff",
          dropdownMessageBackground: "rgba(0, 0, 0, 0.25)",
          dropdownRoom: "#9e9e9e",
          dropdownScroll: "#0a0a0a",
          microphone: "#9600ff",
          audioPlay: "#455247",
          audioPause: "#455247",
          audioCancel: "#eb4034",
          audioConfirm: "#1ba65b",
        },
      },
    };
  },
  components: {
    BCardActions,
    ChatWindow,
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BModal,
    // 3rd Party
    VuePerfectScrollbar,
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("userData"))._id;
    },
    styleChat() {
      // Assuming "light" and "dark" are the possible values of this.$store.state.appConfig.layout.skin
      return this.$store.state.appConfig.layout.skin || "light";
    },
  },

  methods: {
    getRoomInfo({ room }) {
      this.roomId = room.roomId;
      this.messagesloaded = false;
      this.getMessages(room.roomId);
    },

    getMessages(roomId) {
      try {
        if (roomId) {
          axios
            .get(
              "https://backendapinodejs.timecheckit.com/api/message/room/" + roomId
            )
            .then((response) => {
              console.log("rooooms", response);
              this.messages = response.data;
            })
            .catch((error) => {
              console.error("There was an error!", error.response.data);
            });
          this.messagesloaded = true;
        }
      } catch (error) {
        console.log(error);
        this.messagesloaded = false;
      }
    },

    async getRooms() {
      console.log("getRooms");
      try {
        const res = await axios.get(
          "https://backendapinodejs.timecheckit.com/api/chatRoom/" + this.user
        );
        this.loadingRooms = false;
        this.rooms = res.data.map((room) => ({
          ...room,
          users: this.getRoomUsers(room),
          roomId: room._id,
          roomName: this.getRoomName(room),
          avatar: this.getOtherUserAvatar(room),
        }));
        console.log("getRooms");
        console.log("getRooms", this.rooms);
      } catch (error) {
        console.log(error);
      }
    },

    getOtherUser(users) {
      return users.find((user) => user._id != this.user);
    },

    getOtherUserAvatar({ users }) {
      const otherUser = this.getOtherUser(users);
      //  if (otherUser) {
      //    return !otherUser.photo
      //   ? "./img/13-small.d796bffd.png"
      //   : `https://res.cloudinary.com/eloboost/image/upload/v1617048577/${otherUser.photo}`;
      // }else{
      //   return "./img.svg"
      // }
      return "https://res.cloudinary.com/dfsd4vgsl/image/upload/v1705694100/vecteezy_user-profile-icon-profile-avatar-user-icon-male-icon_20911740_mxkvsa.png";
    },

    getRoomName({ users }) {
      const otherUser = this.getOtherUser(users);
      console.log("otherUser", otherUser);
      if (otherUser) {
        return `${otherUser.name || ""}`;
      }else{
        return "TimeCheck User"
      }
    },

    getRoomUsers({ users }) {
      return users.map((user) => ({
        name: `${user.name || ""}} `,
        ...user,
      }));
    },

    async sendMessage(message) {
      console.log("message", message);
      try {
        message.sender_id = this.user;
        if (message.file) {
          message.file.url = await this.uploadFile(message.file);
        }
        console.log("message", message);
        const res = await axios.post(
          "https://backendapinodejs.timecheckit.com/api/message",
          message
        );
        this.messages = [...this.messages, res.data.messageToSend];
        console.log("send message", this.messages);
      } catch (err) {
        console.log(err);
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.blob);
      formData.append("upload_preset", "jhyzlocs");
      return await axios
        .post(`https://api.cloudinary.com/v1_1/dmdd546ps/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data.secure_url;
        })
        .catch((err) => console.log(err));
    },
    async sendReaction({ roomId, messageId, reaction, remove }) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.reactions = message.reactions
        ? { ...message.reactions, [reaction.name]: [this.user] }
        : { [reaction.name]: [this.user] };
      try {
        console.log("reaction", messageId, { reactions: message.reactions });
        await axios
          .put("https://backendapinodejs.timecheckit.com/api/message/" + messageId, {
            reactions: message.reactions,
          })
          .then((response) => {})
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });
      } catch (err) {
        console.log(err);
      }
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
      console.log(this.messages);
    },

    async editMessage({ messageId, newContent }) {
      try {
        console.log("edit", messageId, newContent);
        await axios
          .put("https://backendapinodejs.timecheckit.com/api/message/" + messageId, {
            content: newContent,
          })
          .then((response) => {
            this.editLocalMessage(messageId, newContent);
          })
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });
        this.editLocalMessage(messageId, newContent);
      } catch (err) {
        console.log(err);
      }
    },

    editLocalMessage(messageId, newContent) {
      let messageIndex = this.messages.findIndex(
        (message) => message._id == messageId
      );
      let message = this.messages[messageIndex];
      message.content = newContent;
      this.messages[messageIndex] = message;
      this.messages = [...this.messages];
    },
    async deleteMessage({ messageId }) {
      try {
        await axios
          .delete(
            "https://backendapinodejs.timecheckit.com/api/message/" + messageId
          )
          .then((response) => {
            this.deleteLocalMessage(messageId);
          })
          .catch((error) => {
            console.error("There was an error!", error.response.data);
          });
      } catch (err) {
        console.log(err);
      }
    },
    deleteLocalMessage(messageId) {
      let index = this.messages.findIndex(
        (message) => message._id.toString() == messageId
      );
      this.messages.splice(index, 1);
      this.messages = [...this.messages];
    },
    openFile({ message, action }) {
      switch (action) {
        case "preview":
          // const win = window.open(message.file.url, "_blank");
          // win.focus();
          // this.showModal = true;
          this.$refs["my-modal"].show();
          this.activeImage = message.file.url;
          break;
        case "download":
          axios
            .get(message.file.url, {
              responseType: "blob",
            })
            .then((res) => {
              const url = window.URL.createObjectURL(res.data);
              const a = document.createElement("a");
              a.href = url;
              a.download = message.file.name;
              a.click();
            })
            .catch((err) => console.log(err));
      }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
  async mounted() {
    await this.getRooms();
    this.interval = setInterval(() => {
      this.getMessages(this.roomId);
      console.log(this.$store.state.appConfig.layout.skin)
    }, 3000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" >
// scoped
body .content-right {
  width: 100% !important;
}
html .navbar-floating.footer-static .app-content .content-area-wrapper {
  height: 100% !important;
}
[dir] .vac-card-window {
  border: 1px solid #d8d6de !important;
  box-shadow: none !important;
  // --chat-color-caret: #9600ff !important;
  // --chat-border-color-input-selected: #9600ff !important;
  // --chat-sidemenu-color-active: #9600ff !important;
  // --chat-message-color-new-messages: #9600ff !important;
  // --chat-icon-color-add: #9600ff !important;
  // --chat-icon-color-send
  // --chat-icon-color-send
}
[dir] .vac-container-scroll[data-v-398ce942] {
  background: url("./chat.png") !important;
  background-size: cover;
  background-position: center;
}
.vac-rooms-container .vac-svg-button {
  display: none;
}
// .vac-card-window{
//   background-color: #161d31;
// }
// .vac-room-list{
//   background-color: #161d31;
// }
</style>



